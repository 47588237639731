div[map-search]
{
    border: $colorAccent 4px solid;

    button.btn
    {
        border: 0;
        background-color: $colorAccent;

        &:hover
        {
            background-color: $colorStudents;
        }

        i
        {
            margin-left: 0;
        }
    }
}

.map-iframe,
div[map-element]
{
    width: 100%;
    aspect-ratio: 3/2;
    margin-top: 1rem;
    margin-bottom: 2rem;
    height: auto;
}

.university-map div[map-element]
{
    aspect-ratio: 5/2;

    @include media-breakpoint-down(sm)
    {
        aspect-ratio: 1/1;
    }
}

div[map-element]
{
    .gmaps-info-window
    {
        padding: 1rem;
        max-width: 260px;
        text-align: center;

        > a
        {
            text-decoration: none;
        }

        h4
        {
            font-size: 1.5rem;
        }

        p
        {
            line-height: 1.5;
            font-size: .9rem;
        }

        img
        {
            width: 100%;
            max-width: 20rem;
            display: block;
            margin: 0 auto 1rem;
        }
    }
}

body.map-header header#page-header
{
    min-height: 32em;

    div[map-element]
    {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}


div[map-directions]
{
    margin: 1rem 0;

    .adp
    {
        table
        {
            width: 100%;
        }
    }

    .adp-placemark td
    {
        padding: 6px 12px;
    }
}
