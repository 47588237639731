@import "../../../css/inc/variables";

[arrange-booking],
[utilities-calculator]
{
    > div
    {
        display: flex;
        flex-direction: column-reverse;
    }

    section
    {
        margin: 3rem 0;
    }

    .column-image > svg
    {
        height: 12rem;
        margin-bottom: 2rem;
    }

    .column-heading > h4
    {
        color: $colorAlternate;
        font-size: 1.75rem;
    }

    .flex-columns
    {
        align-items: stretch;

        .column
        {
            padding: 4rem 2rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
        }
    }

    .column p
    {
        margin: 0;
        line-height: 1.2;
        font-size: 1.125rem;

        strong
        {
            font-weight: 800;
        }

        small
        {
            font-size: .8rem;
        }
    }

    legend
    {
        margin: 0 0 1.5rem;
        color: $colorAccent;
    }
}

.bedroom-select
{
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;

    h4:first-child
    {
        color: $colorAlternate;
    }

    ul
    {
        padding: 0;
        margin: 2rem auto 2rem;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        font-size: 2rem;
        gap: 1rem;
        justify-content: center;

        li
        {
            background-color: $colorInverse;
            color: $colorAccent;
            padding: 1.5rem .75rem;
            min-width: 5rem;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            flex: 1 0 5rem;

            &.active
            {
                background-color: $colorAlternate;
                color: $colorInverse;
                font-weight: 800;
            }

            @media (max-width: 60rem)
            {
                padding: 1rem 0.75rem;
                min-width: 3rem;
                flex: 1 0 4rem;
                font-size: 1rem;
            }
        }
    }

    p
    {
        text-transform: lowercase;

        &:last-of-type
        {
            margin-bottom: 3rem;
        }
    }

    a
    {
        font-weight: 800;
        text-decoration: none;
    }
}

form + .bedroom-select
{
    margin-top: 5rem;
}

.react-calendar,
.time-selector
{
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
    background-color: $colorInverse;
    box-shadow: $baseShadow;

    @media (min-width: 60rem)
    {
        font-size: 1.75rem;
    }

    @media (max-width: 25rem)
    {
        font-size: .875rem;
    }
}

%selector-header
{
    background-color: $colorAlternate;
    display: flex;

    button
    {
        background-color: transparent;
        color: $colorInverse;
        border: 0;
        padding: .75em 1.25em;

        &[disabled]
        {
            opacity: 0.5;
        }
    }
}

.react-calendar
{
    &__navigation
    {
        @extend %selector-header;

        &__label
        {
            font-weight: bold;
            text-transform: uppercase;
        }

        &__prev2-button,
        &__next2-button
        {
            display: none;
        }
    }

    &__month-view__weekdays
    {
        background-color: $colorAccent;
        color: $colorInverse;
        text-align: center;
        text-transform: lowercase;

        &__weekday
        {
            padding: .75em;

            > abbr
            {
                text-decoration: none;
                font-weight: 800;
            }
        }
    }

    &__month-view__days
    {
        &__day
        {
            padding: .75em;
            background-color: transparent;
            border: 0;
            color: $colorAccent;

            &[disabled]
            {
                opacity: 0.33;
            }

            &:hover:not([disabled])
            {
                background-color: $colorAlternate;
                font-weight: 800;
                color: $colorInverse;
            }
        }
    }
}

.time-selector
{
    > .time-header
    {
        @extend %selector-header;
        align-items: center;

        h4
        {
            flex-grow: 1;
            text-align: center;
            margin: 0;
            padding: .75em 1.25em;
            color: $colorInverse;
            text-transform: uppercase;

            @media (max-width: 40rem)
            {
                font-size: 1.5rem;
            }
        }
    }

    > .time-group
    {
        display: flex;
        justify-content: center;
        padding: 1rem;
        font-size: .85em;

        button
        {
            background-color: $colorInverse;
            border: 0;
            padding: .5em .75em;
            box-shadow: $baseShadow;
            color: $colorAccent;

            &.active
            {
                background-color: $colorAlternate;
                color: $colorInverse;
            }
        }
    }

    > .time-options
    {
        > ul
        {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0 .25rem;
            list-style-type: none;

            > li
            {
                padding: .75em 1.25em;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 0 1 50%;

                &:nth-child(4n+1),
                &:nth-child(4n+4)
                {
                    background-color: $colorBaseLight;
                }

                &:after
                {
                    @include line-awesome();
                    content: "\f055";
                    color: $colorAccent;
                }
            }
        }
    }

    > .time-footer
    {
        padding: .75rem .25rem;
        margin: 0;
        text-align: center;

        a
        {
            text-decoration: none;
            color: $colorAlternate;
            font-weight: bold;
        }

        @media (max-width: 60rem)
        {
            font-size: .8rem;
        }
    }
}

.booking-summary
{
    legend
    {
        margin-top: 1rem;
    }

    h5
    {
        font-weight: normal;
        font-size: 1.333rem;
        text-transform: none;
    }

    h5 + p
    {
        font-size: 1.5rem;
        line-height: 1.25;
        font-weight: 800;

        > small
        {
            font-size: 1.25rem;
            font-weight: normal;
        }
    }

    @media (max-width: 40rem)
    {
        .row > *
        {
            margin-bottom: 1.25rem;
        }
    }

    @media (max-width: 60rem)
    {

        h5
        {
            font-size: 1.125rem;
        }

        h5 + p
        {
            font-size: 1.3333rem;

            > small
            {
                font-size: 1rem;
            }
        }
    }
}

.booking-nav
{
    display: flex;
    justify-content: center;
    margin: 0 auto 2rem;
    gap: 1rem;
    list-style-type: none;

    > li
    {
        text-indent: -1000vw;
        background-color: rgba(black, 0.2);
        width: .5rem;
        height: .5rem;
        border-radius: 1rem;
        cursor: pointer;

        &.active
        {
            background-color: $colorAlternate;
            cursor: initial;
        }

        &.active ~ li
        {
            cursor: initial;
        }
    }
}

.quota-percentage
{
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    > strong
    {
        font-size: 3rem;
        font-weight: 800;
        color: var(--color-secondary);
        margin-right: .667rem;
    }

    @media (max-width: 40rem)
    {
        flex-direction: column;
        gap: 1rem;
    }
}

.quota-summary
{
    display: inline-grid !important;
    margin: 0 auto 3rem;
    gap: 1.5rem;

    p:last-of-type
    {
        margin-bottom: 0;
    }

    > :first-child
    {
        justify-self: end;
    }

    .quota-value
    {
        font-size: 2rem;
        font-weight: 800;
    }

    @media (min-width: 40rem)
    {
        &.fields.cols-2
        {
            grid-template-columns: repeat(2, auto) !important;
            gap: 3rem;
            text-align: left;
            margin-bottom: 3rem;
        }
    }
}

[arrange-booking] .viewing-properties
{
    margin-top: 3rem;
    margin-bottom: 3rem;

    > .viewing-property
    {
        text-align: left;
        padding-bottom: 2rem;
        position: relative;

        > .column-image
        {
            margin: -4rem -2rem 2rem -2rem;
            aspect-ratio: 4 / 3;
            object-fit: cover;
            object-position: center;
        }

        > .property-remove
        {
            position: absolute;
            top: .5rem;
            right: .5rem;
            background-color: transparent;
            border: 0;
            color: $colorInverse;
            text-shadow: 0 0 4px $colorBaseDark;
            font-size: 3rem;
            opacity: 0.333;
            line-height: 1;

            &:hover
            {
                opacity: 0.667;
            }
        }
    }
}

.property-dropdown-wrapper
{
    position: relative;

    &::before
    {
        content: '\f107';
        font-family: 'FontAwesome';
        position: absolute;
        right: 1rem;
        color: $colorAlternate;
        pointer-events: none;
    }

    ::placeholder
    {
        color: $colorAlternate;
        text-transform: uppercase;
    }
}

.property-dropdown
{
    position: absolute;
    width: calc(100% + 8px);
    left: -4px;
    top: 100%;
    border: $colorAccent 4px solid;
    border-top-width: 0;
    z-index: 10;

    > .property-dropdown-item
    {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 9rem 1fr 4rem;
        border-top: $colorAccent 1px solid;
        background-color: $colorInverse;
        gap: 1.5rem;
        cursor: pointer;
        align-items: center;

        > img
        {
            width: 100%;
            aspect-ratio: 4 / 3;
            object-fit: cover;
            object-position: center;
        }

        > div
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1rem 0;
            text-align: left;
        }

        > i
        {
            color: $colorAlternate;
            font-size: 1.5rem;
        }

        h5
        {
            line-height: 1.1;
            margin-bottom: .333rem;
        }

        p:last-of-type
        {
            text-transform: none;
            margin-bottom: 0;
            line-height: 1.1;
        }

        @media (max-width: 36rem)
        {
            grid-template-columns: 6rem 1fr;

            > i
            {
                display: none;
            }
        }
    }

    > .property-dropdown-empty
    {
        border-top: $colorAccent 1px solid;
        background-color: $colorInverse;
        padding: 1rem;
    }
}

.property-dropdown-trap
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
