$mainFont: Mont, sans-serif;
$altFont: Mont, sans-serif;

$colorInverse: #fff;
$colorBaseLight: #f7f7f7;
$colorBaseMid: #444;
$colorBaseDark: black;

$colorStudents: #66cceb;
$colorLandlords: #f05759;
$colorResidential: #71C7B1;
$colorStudios: #febc1e;
$colorTransparent: rgba(0,0,0,0);

$colorAccent: #173c73;
$colorAlternate: $colorStudents;

$mainOpacity: 0.8;

$textSizeBase: 16px;
$baseShadow: 0px 0px 10px 0px rgba(#999, 0.2);
$borderSize: 3px;
$altBorderSize: 2px;

$containerPaddingY: 4rem;
$containerPaddingYLarge: 6rem;
$containerPaddingX: 3rem;
$containerMaxWidth: 1920px;

@mixin uppercaseText()
{
    text-transform: uppercase;
}

@mixin arrowButton()
{
    border-width: 4px;
    border-style: solid;
    padding: 1rem 3.5rem 1rem 2rem;
    border-radius: 0;
    font-weight: 800;
    font-size: 1.5rem;
    text-transform: lowercase;
    background-color: $colorTransparent;
    display: block;
    text-decoration: none;
    margin-right: .25rem;

    &:hover
    {
        color: $colorStudents;
        border-color: $colorAccent;
    }

    &:after
    {
        content: "\f067";
        position: absolute;
        top: 50%;
        right: 0;
        font-family: FontAwesome;
        color: $colorStudents;
        padding-right: 2.25rem;
        font-size: 1.25rem;
        transform: translateY(-50%);
    }
}

@function encodecolor($string)
{
    @if type-of($string) == 'color'
    {
        $hex: str-slice(ie-hex-str($string), 4);
        $string: unquote("#{$hex}");
    }

    $string: '%23' + $string;
    @return $string;
}

@mixin line-awesome()
{
    font-family: 'Line Awesome Free';
    font-weight: bold;
}