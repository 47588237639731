@import "inc/variables";
@import "inc/bootstrap/defer";

$la-css-prefix: la;
@import "~line-awesome/dist/line-awesome/scss/line-awesome";
@import "~font-awesome/scss/font-awesome";
@import "~jssocials/dist/jssocials.css";
@import "~jssocials/dist/jssocials-theme-flat.css";

html
{
    background-color: var(--color-secondary);
}

body
{
    background-color: $colorInverse;
}

section.stats
{
    padding: $containerPaddingY 0;

    .stat-content
    {
        text-align: center;

        h2
        {
            margin-bottom: 0;
            color: $colorStudents;
        }

        h5
        {
            text-transform: uppercase;
        }
    }
}

.section-students
{
    div.stat-content h2
    {
        color: $colorStudents;
    }
}

.section-landlords
{
    div.stat-content h2
    {
        color: $colorLandlords;
    }
}

.section-residential
{
    div.stat-content h2
    {
        color: $colorResidential;
    }
}

.section-studios
{
    div.stat-content h2
    {
        color: $colorStudios;
    }
}

.section-students
{
    section.existing-clients
    {
        h3
        {
            color: $colorStudents;
        }

        i
        {
            color: $colorStudents;
        }
    }
}

.section-landlords
{
    section.existing-clients,
    {
        h3
        {
            color: $colorLandlords;
        }

        i
        {
            color: $colorLandlords;
        }
    }
}

.section-residential
{
    section.existing-clients
    {
        h3
        {
            color: $colorResidential;
        }

        i
        {
            color: $colorResidential;
        }
    }
}

.section-studios
{
    section.existing-clients
    {
        h3
        {
            color: $colorStudios;
        }

        i
        {
            color: $colorStudios;
        }
    }
}

section.utilities-calculator
{
    background-color: var(--color-secondary);

    h3, p
    {
        color: $colorInverse;
    }

    .btn
    {
        border-color: $colorAccent;
        color: $colorInverse;

        i
        {
            color: $colorAccent;
        }
    }
}


section.existing-clients,
section.utilities-calculator
{
    padding: $containerPaddingY $containerPaddingX;

    .existing-content
    {
        justify-self: flex-start;
    }

    @include media-breakpoint-down(md)
    {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

section.contact-help
{
    padding: $containerPaddingYLarge $containerPaddingX;
    background-color: $colorStudents;

    .contact-details
    {
        i
        {
            margin-right: .25rem;
            color: $colorAccent;
            font-size: 1.25rem;
        }

        a
        {
            font-weight: 800;
            text-decoration: none;
            text-transform: lowercase;
            margin-right: 1rem;
            font-size: 1.25rem;
            color: $colorAccent;

            &:hover
            {
                color: $colorInverse;
            }

            &:last-child
            {
                margin-right: 0;
            }

            @include media-breakpoint-down(md)
            {
                font-size: 1.125rem;
            }
        }
    }

    .btn
    {
        margin-top: 1.5rem;

        i
        {
            color: $colorAccent;
        }
    }
}

.page-content-btns,
.existing-btns
{
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.section-students
{
    section.contact-help
    {
        background-color: $colorStudents;
    }
}

.section-landlords
{
    section.contact-help
    {
        background-color: $colorLandlords;
    }
}

.section-residential
{
    section.contact-help
    {
        background-color: $colorResidential;
    }
}

.section-studios
{
    section.contact-help
    {
        background-color: $colorStudios;
    }
}

section.contact-help
{
    .contact-details
    {
        display: flex;
        gap: 1.5rem;
        justify-content: center;

        @include media-breakpoint-down(md)
        {
            flex-direction: column;
            gap: 0;
        }
    }

    @include media-breakpoint-down(md)
    {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}


section.schemes-memberships
{
    padding: $containerPaddingY $containerPaddingX;

    @include media-breakpoint-down(md)
    {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .logo-container
    {
        display: flex;
        gap: 2.5rem;
        flex-wrap: wrap;
        justify-content: center;
    }

    img
    {
        max-width: 13rem;
        max-height: 7rem;
        object-fit: contain;

        @include media-breakpoint-down(md)
        {
            max-width: 8rem;
        }
    }
}


@import "inc/colorbox";
@import "../../../Components/Base/BaseComponent/Content/la-fix";

@import "parts/footer";
@import "parts/maps";
@import "parts/pagination";
@import "../../../Components/Base/BaseComponent/Content/pagination";
