div.pagination
{
    height: 3.5rem;
    font-size: 3rem;
    display: flex;
    justify-content: center;

    > *
    {
        display: flex;
        align-items: center;
        font-size: 1rem;
    }

    span .page-prev
    {
        opacity: .3;
    }

    span.page-prev, span.page-next, a.page-prev, a.page-next
    {
        background-color: $colorInverse;
        color: $colorAccent;

        &.disabled
        {
            opacity: .5;
        }
    }

    span.page-current
    {
        font-size: 1rem;
        font-weight: 400;
    }
}

/* Section Colours */

.section-students div.pagination
{
    span.page-prev, span.page-next, a.page-prev, a.page-next
    {
        color: $colorStudents;
    }
}

.section-landlords div.pagination
{
    span.page-prev, span.page-next, a.page-prev, a.page-next
    {
        color: $colorLandlords;
    }
}

.section-residential div.pagination
{
    span.page-prev, span.page-next, a.page-prev, a.page-next
    {
        color: $colorResidential;
    }
}

.section-studios div.pagination
{
    span.page-prev, span.page-next, a.page-prev, a.page-next
    {
        color: $colorStudios;
    }
}
